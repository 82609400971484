<template>
  <label v-bind:for="component_id" v-if="component_meta.component.properties.show_label == 'Y'">
    {{ component_meta.label }}：
  </label>
  <span class="tip" v-if="component_meta.component.properties.show_label == 'Y'">{{ component_meta.tip }}</span>


  <div class="row" v-bind:class="{'frame-border': show_border}">
    <div v-for="field_meta in component_meta.component.properties.fields" :key="field_meta.variable" :class="[
      'form-group',
      'col-' + field_meta.component.properties.col_width.mobile,
      'col-sm-' + field_meta.component.properties.col_width.pad,
      'col-md-' + field_meta.component.properties.col_width.pad,
      'col-lg-' + field_meta.component.properties.col_width.desktop
    ]">
      <component v-bind:is="field_meta._component" v-bind:field="field_meta" v-bind:value="component_value[field_meta.variable]"
        @input="update_input_value(field_meta, $event)" v-bind:key="field_meta.variable"></component>
    </div>
  </div>
</template>

<script>
export default {
  props: ['field', 'value', 'message'],
  emits: ['input'],
  data() {

    // 數值初始化
    let localValue = this.value;
    if (typeof localValue !== 'object') {
      localValue = {};
    }

    for (let key in this.field.component.properties.fields) {
      let variable = this.field.component.properties.fields[key].variable;
      if (localValue[variable] == undefined || localValue[variable] == null) {
        localValue[variable] = '';
      }
    }

    let localMeta = this.field;
    if (typeof localMeta.component.properties.border !== 'string') {
      localMeta.component.properties.border = 'Y';
    }

    // 元件
    for (let key in localMeta.component.properties.fields) {
      localMeta.component.properties.fields[key]._component =
        this.$microlink.components[localMeta.component.properties.fields[key].component.type].component;
    }

    return {
      component_id: 'frame-' + Math.random().toString().substring(2),
      component_meta: localMeta,
      component_value: localValue,
      show_border : (localMeta.component.properties.border == 'Y')
    }
  },
  mounted() {

  },
  methods: {
    update_input_value: function (meta, $event) {
      this.component_value[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;
      $event.temp = this.component_value;
      this.$emit('input', $event);
    }
  }
}
</script>

<style scoped>
span.tip {
  color: green;
}

div.frame-border {
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 5px;
}

div.frame-border label:not(:first-child) {
  margin-top: 30px;
}
</style>